.CB {
    background-color: #2D2D2D;
    width: 50px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 60%;
    margin-right: 10px;
}